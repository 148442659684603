body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading-splash {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .85);
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
}

.loading-splash .spinner-border {
    width:55px;
    height:55px;
    margin-bottom:1rem;
}

.container {
    margin-top:1rem;
    
}

.navbar-brand {
    padding:0;
}

.navbar-brand img {
    height:50px;
}

#site-navbar > div > a {
    color:white;
}

.big-logo {
    max-height:25vh;
    max-width:60vw;
    display: block;
    margin:auto;
    margin-bottom:1rem;
}

.platform-card-group {
    display:flex;
    justify-content:center;
    align-items:center;
}

.platform-card {
    max-width: 18rem;
    margin:1rem;
}

.center {
    text-align: center;
}

.vertical-center-children {
    display:flex;
    justify-content:center;
    align-items:center;
}

.bundle-card .card-footer {
    padding:0.5rem;
}

.btn {
    color: #fff;
}

.btn.btn-wide {
    width: 100%;
}

.btn-group-vertical.btn-group-wide {
    width:100%;
}

.btn-group-vertical.btn-group-wide .btn {
    width:100%;
}

.btn-group.btn-group-wide {
    display:flex;
}

.btn-group.btn-group-wide .btn:not(.btn-min) {
    flex: 1;
    flex-grow: 1;
}

.btn-group.btn-group-wide .btn-min {
    flex-grow:0;
}

.sharp-corners .btn {
    border-radius: 0;
}

.muted {
    color: #b7b7b7;
}

.category-col {
    padding:1rem;
}

.category-col .card {
    height:100%;
}

.package-checkbox {
    margin-left:1rem;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
}

.nav.nav-pills .nav-item.package-selector {
    border: 1px solid #DEE2E6;
    height: 100%;
}

.nav.nav-pills .nav-item.package-selector:hover {
    background-color: rgba(0,0,0, 0.05);
}

.nav.nav-pills .nav-item.package-selector .nav-link {
    color:inherit;
    height: 100%;
}

.nav.nav-pills .nav-item.package-selector .nav-link.active {
    background-color:rgba(0,0,0,0.10);
}

.nav.nav-pills .nav-item.package-selector .nav-link span {
    display: inline-block;
    height: 100%;
    margin-left:0.5rem;
    margin-right:0.5rem;
}

.huge-text {
    font-size: xx-large;
}

.subcategory {
    margin-bottom:1rem;
}

.copyright {
    color:gray;
    margin-top:1rem;
    margin-bottom:1rem;
    text-align: center;
}

h1.tall-margin {
    margin-top:2rem;
    margin-bottom:2rem;
}

.seamless.card-footer {
    background-color: inherit;
    border-top: 0;
}

.error-box {
    margin:auto;
    background-color:lightsteelblue;
    max-width: 600px;
    width:100vw;
}

.error-box pre {
    margin:0;
    padding:1rem;
    max-height: 20rem;
}

.fail-icon {
    width:100px;
    margin-bottom:1rem;
}

.modal-dialog {
    max-width:700px;
}

.italics {
    font-style: italic
}

.navbar-nav .nav-link {
    margin: auto;
}

.navbar-nav .nav-link {
    line-height: 1;
}

.navbar-nav .nav-item {
    margin: auto;
}

.bis-box {
    max-width: 600px;
    margin: auto;
    padding: 1rem 3rem 0 3rem;
}

.bis-box h6 {
    text-align: center;
    margin-top:1rem;
}

.bis-box input.form-control {
    font-family: monospace;
    text-align: center;
}

.bis-box .tab-content {
    margin-bottom:2rem;
}

.bis-box textarea {
    margin-bottom: 2rem;
    font-family: monospace;
    resize: none;
}